import React, { useEffect, useState } from "react";
import LogoLayout from "../../components/logolayout/logolayout";
import { Navigate, useLocation } from "react-router-dom";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { showToast } from "../../utils/utils";
import axiosInstance from "../../config/axios";

export default function RegisterSubscriptionPage() {
  const location = useLocation();
  const params = location.state;
  const monthlyPlan = params?.plans?.find((p: any) => p.userRole == params?.form.role && p.period == "monthly");
  const yearlyPlan = params?.plans?.find((p: any) => p.userRole == params?.form.role && p.period == "yearly");
  const [selected, setselected] = useState("monthly");
  const [codePromo, setCodePromo] = useState("");
  const redirect = window.history.state.idx == 0;
 
  if (redirect) return <Navigate to={"/register"} replace />;
 
  async function onSubmit() {
    try {
      const { data } = await axiosInstance.post("users/subscribe", {
        period: selected,
        codePromo: codePromo,
        successUrl: window.location.origin + "/register/success" + (params?.form?.fromMobileApp ? "?mobile=yes" : ""),
        cancelUrl: window.location.origin + "/register",
      });
      window.location.href = data.session.url;
      return;
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  return (
    <LogoLayout params={params} onSubmit={onSubmit} progress={70}>
      <h1>Inscription</h1>
      <p className="subtitle">{params.form.role}</p>
      <h2 className="title">Votre abonnement</h2>
      <div style={{ marginTop: 10 }}>
        <p className="legend">
          <img className="labelimg" src={"/images/check2.svg"} alt="" />
          Facturation
        </p>
        <p className="legend">
          <img className="labelimg" src={"/images/check2.svg"} alt="" />
          Gestion de vos clients
        </p>
        <p className="legend">
          <img className="labelimg" src={"/images/check2.svg"} alt="" />
          Tarification de vos activités
        </p>
        <div>
          <div className="subscriptionfree">
            <img className="imgwhite" alt="" src="/images/giftwhite.svg" />
            <img className="imgblue" alt="" src="/images/giftblue.svg" />
            <div className="row">
              <span className="currentR freetext">
                Profitez <span className="currentM freetext">de 4 mois offerts </span> dès maintenant*
              </span>
            </div>
          </div>
          <SubscriptionItem
            free={monthlyPlan.free_days}
            price={monthlyPlan.price}
            title="Mensuel"
            unit="par mois"
            onClick={() => setselected("monthly")}
            selected={selected == "monthly"}
          />
          <SubscriptionItem
            selected={selected == "yearly"}
            free={yearlyPlan.free_days}
            price={yearlyPlan.price}
            onClick={() => setselected("yearly")}
            title="Annuel"
            unit="par an"
          />
        </div>
      </div>
    </LogoLayout>
  );
}
export function SubscriptionItem(props: any) {
  return (
    <div onClick={props.onClick} className={`subscription ${props.selected ? "subselected" : ""}`}>
      <img src={props.selected ? "/images/radio-active.svg" : "/images/radio-inactive.svg"} alt="" />
      <div className="row">
        <span className="currentM">{props.title}</span>
        <span style={{ marginTop: 10, marginBottom: 5 }} className="currentM">
          {props.price}€ <span className="currentR">{props.unit}</span>
        </span>
        {!props.noFree&& <span className="currentR plandesc">
          *Essai de 4 mois gratuit et sans engagement avant le début de la facturation
        </span>}
      </div>
    </div>
  );
}
