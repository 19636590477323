import React from "react";
import StructureLayout from "../../components/structureLayout/structurelayout";

const CGVPage: React.FC = () => {
  return (
    <StructureLayout hideheader={true} showBack={true}>
      <div className="container bg-white py-8">
        <div className="col-md-8 mx-auto px-4">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold mb-4">CONDITIONS GÉNÉRALES D&apos;UTILISATION</h1>
            <p className="text-gray-600">En vigueur au 18/11/2024</p>
          </div>

          <div className="space-y-8">
            <p className="text-start currentR">
              Les présentes conditions générales d&apos;utilisation (dites « CGU ») ont pour objet l&apos;encadrement
              juridique des modalités de mise à disposition du site et des services par <strong>Le Backyard</strong> et
              de définir les conditions d&apos;accès et d&apos;utilisation des services par « l&apos;Utilisateur ». Les
              présentes CGU sont accessibles sur le site à la rubrique « CGU ». Toute inscription ou utilisation du site
              implique l&apos;acceptation sans aucune réserve ni restriction des présentes CGU par l&apos;utilisateur.
              Lors de l&apos;inscription sur le site via le Formulaire d&apos;inscription, chaque utilisateur accepte
              expressément les présentes CGU en cochant la case précédant le texte suivant : « Je reconnais avoir lu et
              compris les CGU et je les accepte ». En cas de non-acceptation des CGU stipulées dans le présent contrat,
              l&apos;Utilisateur se doit de renoncer à l&apos;accès des services proposés par le site. hykonnect.com se
              réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.
            </p>

            {/* Article 1 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 1 - Mentions légales</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  L&apos;édition du site hykonnect.com est assurée par la Société SAS Le Backyard, au capital de 5000
                  euros, immatriculée au RCS de Paris sous le numéro 819 130 873, dont le siège social est situé au 17 B
                  AVENUE PARMENTIER 75011 PARIS.
                </p>
                <p className="text-start currentR">Numéro de téléphone : +33 (0)9 86 35 76 35</p>
                <p className="text-start currentR">Adresse e-mail : contact@lebackyard.fr</p>
                <p className="text-start currentR">Le Directeur de la publication est : Huber Thomas</p>
                <p className="text-start currentR">Numéro de TVA intracommunautaire : FR63819130873</p>
                <p className="text-start currentR">
                  L&apos;hébergeur du site est OVH Cloud, dont le siège social est situé au 2 rue Kellermann - 59100
                  Roubaix - France, avec le numéro de téléphone : +33 9 72 10 10 07.
                </p>
              </div>
            </section>

            {/* Article 2 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 2 - Accès au site</h2>
              <div className="space-y-4">
                <p className="text-start currentR">Le site hykonnect.com permet à l&apos;Utilisateur un accès gratuit aux services suivants...</p>
                <p className="text-start currentR">
                  L&apos;Utilisateur non membre n&apos;a pas accès aux services réservés. Pour cela, il doit
                  s&apos;inscrire en remplissant le formulaire.
                </p>
              </div>
            </section>

            {/* Article 3 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 3 - Collecte des données</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Le site assure à l&apos;Utilisateur une collecte et un traitement d&apos;informations personnelles
                  dans le respect de la vie privée...
                </p>
                <p className="text-start currentR">
                  L&apos;Utilisateur dispose d&apos;un droit d&apos;accès, de rectification, de suppression et
                  d&apos;opposition de ses données personnelles.
                </p>
              </div>
            </section>

            {/* Article 4 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 4 - Propriété intellectuelle</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Les marques, logos, signes ainsi que tous les contenus du site sont protégés par le Code de la
                  propriété intellectuelle...
                </p>
                <p className="text-start currentR">
                  Toute reproduction, publication, ou exploitation des contenus est strictement interdite sans
                  autorisation préalable.
                </p>
              </div>
            </section>

            {/* Article 5 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 5 - Responsabilité</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Les informations sur le site sont réputées fiables, mais le site ne garantit pas qu&apos;il soit
                  exempt d&apos;erreurs ou de défauts.
                </p>
                <p className="text-start currentR">
                  L&apos;Utilisateur s&apos;assure de garder son mot de passe secret et assume les risques liés à
                  l&apos;utilisation de son identifiant et mot de passe.
                </p>
              </div>
            </section>

            {/* Article 6 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 6 - Liens hypertextes</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Des liens hypertextes peuvent être présents sur le site, l&apos;Utilisateur est informé qu&apos;en
                  cliquant dessus, il quitte le site hykonnect.com.
                </p>
              </div>
            </section>

            {/* Article 7 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 7 - Cookies</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Le site utilise des cookies pour améliorer l&apos;expérience utilisateur. Ces cookies sont nécessaires
                  au bon fonctionnement du site.
                </p>
                <p className="text-start currentR">L&apos;Utilisateur peut désactiver les cookies via les paramètres de son navigateur.</p>
              </div>
            </section>

            {/* Article 8 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 8 - Droit applicable et juridiction compétente</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  La législation française s&apos;applique au présent contrat. En cas de litige, les tribunaux français
                  seront compétents.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </StructureLayout>
  );
};

export default CGVPage;
