export function chooseFromFileSystem(props?: { multiple: boolean; patternFile?: string[] }) {
  let fileInput: any = document.getElementById("global-input-file");
  if (!fileInput) {
    fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.id = "global-input-file";
    fileInput.style = "display:none";
    document.body.appendChild(fileInput);
  }
  return new Promise((resolve) => {
    fileInput.multiple = props?.multiple || false;
    fileInput.accept = "";
    if (props?.patternFile)
      fileInput.accept = props.patternFile?.reduce((res, current) => {
        return res + current + ",";
      }, "");
    const onChange = (e: any) => {
      resolve(e.target.files);
      fileInput.removeEventListener("change", onChange);
     try{ 
      document.body.removeChild(fileInput);
     }
     catch(e){}
    };
    fileInput.addEventListener("change", onChange);
    fileInput.click();
  });
}

export async function readLocalFile(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (e) {
      reject(e);
    };
  });
}
