import React, { useEffect, useState } from "react";
import { Modal as Mmodal } from "react-responsive-modal";
import "./modal.css";
import Button from "../button/button";

export default function Modal(props: {
  show: boolean;
  title: string;
  description?: any;
  btnconfirm?: string;
  btnclose?: string;
  onConfirm?: Function;
  onClose?: any;
}) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  return (
    <Mmodal
      closeIcon={() => null}
      classNames={{
        modal: "appmodal",
      }}
      open={open}
      onClose={() => {
        setOpen(false);
        if (props.onClose) props.onClose();
      }}
      center
    >
      <button className="btnclose" onClick={props?.onClose}>
        <img width={28} src="/images/close.svg" alt="" />
      </button>
      <div className="modalcontent">
        <div className="modaltext">
          <span className="subtitle modaltitle">{props.title}</span>
          <div className="currentR modaldesc">{props.description}</div>
        </div>
        <Button className="btn-primary mb-3 mt-3" onClick={props.onConfirm} title={props.btnconfirm || "Confirmer"} />
        <Button className="btn-tertiary" onClick={props.onClose} title={props.btnclose || "Annuler"} />
      </div>
    </Mmodal>
  );
}
