import React from "react";
import "./App.css";
import AppRoutes from "./routes/app.routes";
import AppContextProvider from "./context/app.context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Interceptor from "./components/interceptor/interceptor";
import "bootstrap/dist/css/bootstrap.min.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material";
import "dayjs/locale/fr";
import Modal from "./components/modal/modal";
function App() {
  return (
    <ThemeProvider theme={createTheme({ palette: { primary: { main: "#00354f" } } })}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
        <div className="container-fluid root">
          <ToastContainer limit={1} autoClose={false} bodyClassName={"error-toast"} />
          <AppContextProvider>
            <Interceptor>
              <AppRoutes />
            </Interceptor>
          </AppContextProvider>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
