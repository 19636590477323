import React, { useState } from "react";
import Structurelayout from "../../../components/structureLayout/structurelayout";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { useAppContext } from "../../../context/app.context";
import Button from "../../../components/button/button";
import { FormProvider, useForm } from "react-hook-form";
import { emailReg, formatPhone, phonereg, showToast } from "../../../utils/utils";
import axiosInstance from "../../../config/axios";
import * as _ from "lodash";
export default function ManagerPage() {
  const { appInfos, setAppInfos } = useAppContext();
  const methods = useForm({ mode: "onBlur" });
  const [manager, setmanager] = useState({ ...appInfos.user.manager });
  const disabled = _.isEqual(manager, appInfos.user.manager);
  async function onSubmit(form: any) {
    try {
      const { data } = await axiosInstance.post("/users/account/update", { manager: form });
      setAppInfos({ user: { ...appInfos.user, ...data } });
      showToast("Votre compte a été bien mis a jour", "success");
    } catch (e: any) {
      showToast("Une erreur est survenue");
    }
  }
  return (
    <Structurelayout header={Header()}>
      <FormProvider {...methods}>
        <div className="graycard">
          <div className="row">
            <h3 style={{ marginBottom: 10 }}>Votre gérant</h3>
            <div className="col-md-4">
              <TextInput
                onChange={(value: any) => setmanager((old: any) => ({ ...old, last_name: value }))}
                required="Nom invalide"
                value={manager.last_name}
                label="Nom"
                name="last_name"
              />
            </div>
            <div className="col-md-4">
              <TextInput
                onChange={(value: any) => setmanager((old: any) => ({ ...old, first_name: value }))}
                required="Prénom invalide"
                value={manager.first_name}
                label="Prénom"
                name="first_name"
              />
            </div>
            <div className="col-md-4">
              <TextInput
                name="email"
                autocomplete="off"
                type="email"
                value={manager.email}
                onChange={(value: any) => setmanager((old: any) => ({ ...old, email: value }))}
                label="Adresse e-mail"
                validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
              />
            </div>
            <div className="col-md-4">
              <TextInput
                validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
                name="phone"
                onChange={(value: any) => setmanager((old: any) => ({ ...old, phone: value }))}
                value={manager.phone}
                format={formatPhone}
                maxLength={14}
                placeHolder="06.00.00.00.00"
                label="Téléphone"
              />
            </div>
          </div>
        </div>
        <div className="mt-4 flexEnd">
          <div>
            <Button
              className="btn-primary graydisabled"
              onClick={methods.handleSubmit(onSubmit)}
              title="Enregistrer"
              disabled={disabled}
            />
          </div>
        </div>
      </FormProvider>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Mon profil</h1>
    </div>
  );
}
