import React from "react";
import StructureLayout from "../../components/structureLayout/structurelayout";

const MentionsPage: React.FC = () => {
  return (
    <StructureLayout hideheader={true} showBack={true}>
      <div className="container bg-white py-8">
        <div className="col-md-8 mx-auto px-4">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold mb-4">Mentions légales</h1>
            <p className="text-gray-600">En vigueur au 18/11/2024</p>
          </div>

          <div className="space-y-8">
            {/* Définitions */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">Définitions</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  <strong>Client :</strong> Tout professionnel ou personne physique capable au sens des articles 1123 et suivants du Code civil, ou personne morale, qui visite le site objet des présentes conditions générales.
                </p>
                <p className="text-start currentR">
                  <strong>Prestations et Services :</strong> Le site hykonnect.com met à disposition des clients ses services.
                </p>
                <p className="text-start currentR">
                  <strong>Contenu :</strong> Ensemble des éléments constituants l&apos;information présente sur le site, notamment textes, images, vidéos.
                </p>
                <p className="text-start currentR">
                  <strong>Informations clients :</strong> Données personnelles susceptibles d&apos;être détenues par hykonnect.com pour la gestion de votre compte, de la relation client et à des fins d&apos;analyses et de statistiques.
                </p>
                <p className="text-start currentR">
                  <strong>Utilisateur :</strong> Internaute se connectant ou utilisant le site.
                </p>
                <p className="text-start currentR">
                  <strong>Informations personnelles :</strong> Les informations qui permettent, sous quelque forme que ce soit, directement ou non, l&apos;identification des personnes physiques auxquelles elles s&apos;appliquent (article 4 de la loi n° 78-17 du 6 janvier 1978). Les termes « données à caractère personnel », « personne concernée », « sous-traitant » et « données sensibles » ont le sens défini par le RGPD (n° 2016-679).
                </p>
              </div>
            </section>

            {/* Article 1 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">1. Présentation du site internet</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  En vertu de l&apos;article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l&apos;économie numérique, voici l&apos;identité des intervenants concernant le site hykonnect.com :
                </p>
                <ul className="list-disc pl-8">
                  <li><strong>Propriétaire :</strong> SAS Hykonnect, capital social de 15 000€, TVA : FR14925159261, siège au 138 rue du Bourg Belé, 72000 Le Mans.</li>
                  <li><strong>Responsable publication :</strong> Gladys de Labbey – gladys@hykonnect.com.</li>
                  <li><strong>Webmaster :</strong> Gladys de Labbey – gladys@hykonnect.com.</li>
                  <li><strong>Hébergeur :</strong> OVH, 2 rue Kellermann, 59100 Roubaix.</li>
                  <li><strong>Délégué à la protection des données (DPO) :</strong> Gladys de Labbey – gladys@hykonnect.com.</li>
                </ul>
              </div>
            </section>

            {/* Article 2 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">2. Conditions générales d&apos;utilisation</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  L&apos;utilisation du site hykonnect.com implique l&apos;acceptation des conditions générales. Ces conditions sont susceptibles d&apos;être modifiées à tout moment et doivent être consultées régulièrement. Le site est en principe accessible à tout moment, sauf interruption pour maintenance. Toute reproduction ou exploitation non autorisée du site ou de ses éléments est interdite.
                </p>
              </div>
            </section>

            {/* Article 3 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">3. Description des services fournis</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Le site hykonnect.com vise à fournir des informations sur les activités de la société. Malgré tous les efforts pour garantir des informations exactes, des erreurs ou omissions peuvent survenir. Ces informations sont susceptibles d&apos;évoluer.
                </p>
              </div>
            </section>

            {/* Article 4 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">4. Limitations contractuelles sur les données techniques</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Le site utilise la technologie JavaScript. L&apos;utilisateur s&apos;engage à utiliser un matériel récent, sans virus et avec un navigateur à jour. Le site est hébergé dans l&apos;UE, conformément au RGPD.
                </p>
              </div>
            </section>

            {/* Article 5 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">5. Propriété intellectuelle et contrefaçons</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Le site et son contenu (textes, images, vidéos, etc.) sont protégés par le droit d&apos;auteur. Toute reproduction ou exploitation sans autorisation écrite préalable est interdite.
                </p>
              </div>
            </section>

            {/* Article 6 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">6. Limitations de responsabilité</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Le site ne pourra être tenu responsable des dommages liés à son utilisation, notamment en cas de bug ou d&apos;incompatibilité technique. Les espaces interactifs (formulaire de contact) peuvent être modérés en cas de contenu illicite.
                </p>
              </div>
            </section>

            {/* Article 7 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">7. Gestion des données personnelles</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Le traitement des données personnelles est conforme au RGPD. L&apos;utilisateur dispose de droits (accès, rectification, suppression, etc.) qu&apos;il peut exercer en contactant Hykonnect par écrit à l&apos;adresse suivante :
                </p>
                <p className="text-start currentR">
                  Hykonnect – DPO, Gladys de Labbey, 138 rue du Bourg Belé, 72000 Le Mans.
                </p>
              </div>
            </section>

            {/* Article 8 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">8. Notification d&apos;incident</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  En cas de brèche de sécurité impactant les données, Hykonnect s&apos;engage à informer les utilisateurs concernés. Les données personnelles ne sont ni vendues ni échangées sans consentement préalable.
                </p>
              </div>
            </section>

            {/* Article 9 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">9. Liens hypertextes, cookies et balises internet</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Le site utilise des cookies pour améliorer l&apos;expérience utilisateur. L&apos;utilisateur peut configurer son navigateur pour refuser ces cookies. Des balises internet peuvent être utilisées à des fins d&apos;analyse.
                </p>
              </div>
            </section>

            {/* Article 10 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">10. Droit applicable et attribution de juridiction</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Tout litige lié à l&apos;utilisation du site est soumis au droit français. La juridiction compétente est celle de Le Mans.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </StructureLayout>
  );
};

export default MentionsPage;
