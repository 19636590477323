import React, { useState } from "react";
import StructureLayout from "../../../components/structureLayout/structurelayout";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import SelectInput from "../../../components/form/inputs/select-input/select-input";
import DateTimeInput from "../../../components/form/inputs/datetime-input/date-time-input";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../../components/button/button";
import CheckBoxInput from "../../../components/form/inputs/check-input/check-input";
import moment from "moment";
import AutoCompleteInput from "../../../components/form/inputs/autocomplete/autocomplete-input";
import DocumentPicker from "../../../components/documentpicker/documentpicker";
import "./Client.css";
import PlacesInput from "../../../components/form/inputs/places-input/places-inputs";
import { emailReg, formatPhone, phonereg, showToast } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../config/axios";

const AddClient: React.FC = () => {
  const methods = useForm();
  const [isHyconnectClient, setIsHyconnectClient] = useState<boolean>(false);
  const [isUnder18, setIsUnder18] = useState<boolean>(false);
  const [fileUSER, setfileUSER]: any = useState(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [, setSubmitted] = useState<boolean>(false);

  const isButtonDisabled = (() => {
    if (!isHyconnectClient && !selectedUserId) {
      return true;
    }

    if (isHyconnectClient) {
      const { first_name, last_name, role, email, telephone, dateDeNaissance, adress } = methods.watch();

      if (!first_name || !last_name || !role || !email || !telephone || !dateDeNaissance || !adress) {
        return true;
      }

      if (isUnder18) {
        const { nomTuteur, prenomTuteur, emailTuteur, telephoneTuteur, birthTuteur } = methods.watch();
        if (!nomTuteur || !prenomTuteur || !emailTuteur || !telephoneTuteur || !birthTuteur) {
          return true;
        }
      }
    }

    // If all validations pass
    return false;
  })();

  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    setSubmitted(true);

    const formData = new FormData();

    if (!isHyconnectClient && selectedUserId) {
      formData.append("fromUser", "true");
      formData.append("user", selectedUserId);
    } else {
      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name);
      formData.append("role", data.role);
      formData.append("email", data.email);
      formData.append("phone", data.telephone);
      formData.append("level", data.niveau);
      formData.append("galop", data.galop);
      formData.append("birth_date", moment(data.dateDeNaissance).toISOString());
      formData.append("address", JSON.stringify(data.adress));
    }

    if (isUnder18) {
      formData.append(
        "tuteur",
        JSON.stringify({
          first_name: data.nomTuteur,
          last_name: data.prenomTuteur,
          email: data.emailTuteur,
          birth_date: data.birthTuteur,
          phone: data.telephoneTuteur,
        }),
      );
    }

    if (fileUSER) {
      formData.append("photo", fileUSER); // Append file
    }

    try {
      const response = await axiosInstance.post("/structure/clients/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/structure/clients");
    } catch (error: any) {
      if (error?.response?.status == 409) {
        showToast("Ce client a déjà été ajouté.");
      } else {
        showToast("Une erreur est survenue");
      }
    }
  };
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

  return (
    <StructureLayout
      showBack={true}
      header={<h1 style={{ marginTop: 20, marginBottom: 30 }}>Ajout d’un nouveau client</h1>}
    >
      <FormProvider {...methods}>
        <div className="add-client-form">
          <div className="graycard searchaddclient">
            <h3 style={{ marginBottom: 10 }}>Rechercher un client</h3>

            <AutoCompleteInput
              name="client"
              empty={isHyconnectClient}
              url="/structure/clients/search/user"
              label="Client"
              className="searchinp"
              required={!isHyconnectClient ? "Client invalide" : false}
              autocomplete="off"
              placeHolder="Client"
              onSelectItem={(userId) => {
                setSelectedUserId(userId); // Set the selected user ID
              }}
              disabled={isHyconnectClient} // Disable when isHyconnectClient is true
            />
            <CheckBoxInput
              label="Le client n'utilise pas Hykonnect"
              onChange={(value: any) => {
                setIsHyconnectClient((old) => !old);
              }}
            />
          </div>

          {isHyconnectClient && (
            <>
              <div style={{ marginTop: 30 }} className="graycard ">
                <h3 style={{ marginBottom: 10 }}>Informations du Client</h3>
                <div className="row">
                  <div className="col-md-4">
                    <TextInput
                      required="Nom invalide"
                      autocomplete="off"
                      type="text"
                      name="last_name"
                      label="Nom"
                      placeHolder="Nom"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextInput
                      required="Prénom invalide"
                      autocomplete="off"
                      type="text"
                      name="first_name"
                      label="Prénom"
                      placeHolder="Prénom"
                    />
                  </div>
                  <div className="col-md-4">
                    <SelectInput
                      label="Type de client"
                      name="role"
                      placeHolder="Type de client"
                      required="Il faux sélectionner le type de client"
                      options={[
                        { value: "Cavalier", label: "Cavalier" },
                        { value: "Propriétaire", label: "Propriétaire" },
                      ]}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextInput
                      autocomplete="off"
                      type="tel"
                      validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
                      format={formatPhone}
                      maxLength={14}
                      name="telephone"
                      label="Téléphone"
                      placeHolder="00 00 00 00 00"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextInput
                      autocomplete="off"
                      type="text"
                      validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
                      name="email"
                      label="E-mail"
                      placeHolder="adresse@mail.fr"
                    />
                  </div>
                  <div className="col-md-4">
                    <PlacesInput
                      required="Adresse invalide"
                      autocomplete="off"
                      type="text"
                      name="adress"
                      label="Adresse"
                      placeHolder="Adresse"
                    />
                  </div>
                  <div className="col-md-4">
                    <DateTimeInput
                      type="date"
                      required="Date de naissance invalide"
                      name="dateDeNaissance"
                      label="Date de naissance"
                      placeholder="JJ/MM/AAAA"
                      onChange={(val: any) => {
                        setIsUnder18(moment().diff(moment(val), "years") < 18);
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <SelectInput
                      label="Galop obtenu"
                      name="galop"
                      placeHolder="Galop obtenu"
                      options={[
                        { value: "Aucun", label: "Aucun" },
                        { value: "Galop 1", label: "Galop 1" },
                        { value: "Galop 2", label: "Galop 2" },
                        { value: "Galop 3", label: "Galop 3" },
                        { value: "Galop 4", label: "Galop 4" },
                        { value: "Galop 5", label: "Galop 5" },
                        { value: "Galop 6", label: "Galop 6" },
                        { value: "Galop 7", label: "Galop 7" },
                        { value: "Galop 7+", label: "Galop 7+" },
                      ]}
                    />
                  </div>
                  <div className="col-md-4">
                    <SelectInput
                      label="Niveau"
                      name="niveau"
                      placeHolder="Niveau"
                      options={[
                        { value: "Débutant", label: "Débutant" },
                        { value: "Intermédiaire", label: "Intermédiaire" },
                        { value: "Loisir", label: "Loisir" },
                        { value: "Confirmé", label: "Confirmé" },
                        { value: "Compétitions club/poney", label: "Compétitions club/poney" },
                        { value: "Compétitions amateur", label: "Compétitions amateur" },
                        { value: "Compétitions pro", label: "Compétitions pro" },
                      ]}
                    />
                  </div>
                  <div className="col-md-4">
                    <DocumentPicker
                      pattern={["image/*"]}
                      btntitle={fileUSER ? "Changer de photo" : "Ajouter une photo"}
                      label="Photo du client"
                      onChange={setfileUSER}
                    />
                  </div>
                </div>
              </div>

              {isUnder18 && (
                <div className="graycard" style={{ marginTop: 30 }}>
                  <h3 style={{ marginBottom: 10 }}>Tuteur</h3>
                  <div className="row">
                    <div className="col-md-4">
                      <TextInput
                        autocomplete="off"
                        required="Nom invalide"
                        type="text"
                        name="nomTuteur"
                        label="Nom"
                        placeHolder="Nom"
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        autocomplete="off"
                        required="Prénom"
                        type="text"
                        name="prenomTuteur"
                        label="Prénom"
                        placeHolder="Prénom"
                      />
                    </div>
                    <div className="col-md-4">
                      <DateTimeInput
                        name="birthTuteur"
                        required="Date de naissance invalide"
                        label="Date de naissance"
                        max={eighteenYearsAgo}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        type="text"
                        validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
                        format={formatPhone}
                        maxLength={14}
                        name="telephoneTuteur"
                        autocomplete="off"
                        label="Téléphone"
                        placeHolder="00 00 00 00 00"
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        autocomplete="off"
                        validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
                        type="text"
                        name="emailTuteur"
                        label="Email"
                        placeHolder="email@mail.com"
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div style={{ marginTop: 30 }} className="w-auto float-end">
            <Button
              disabled={isButtonDisabled}
              title="Ajouter"
              type="submit"
              onClick={methods.handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </FormProvider>
    </StructureLayout>
  );
};

export default AddClient;
