import React from "react";
import "./404.page.css";
import Structurelayout from "../../components/structureLayout/structurelayout";
import { useAppContext } from "../../context/app.context";
import { useLocation } from "react-router-dom";
export default function Page404() {
  const { appInfos } = useAppContext();
  const router = useLocation();
  function Wrapper(p: any) {
    return appInfos?.user?.status == "Active" &&
      ((appInfos?.user?.role == "Structure" && router.pathname.startsWith("/structure")) ||
        (appInfos?.user?.role == "Prestataire" && router.pathname.startsWith("/prestataire"))) ? (
      <Structurelayout>{p.children}</Structurelayout>
    ) : (
      <div style={{ display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center", height: "100vh" }}>
        {p.children}
      </div>
    );
  }
  return (
    <Wrapper>
      <div className="page404">
        <div className="container">
          <div className="error">
            <h1>404</h1>
            <p>Page non trouvée.</p>
            <a href="/">{"Retour à la page d'accueil"}</a>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
