import React from "react";
import Table from "../../../../components/table/table";
import Structurelayout from "../../../../components/structureLayout/structurelayout";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../components/button/button";
import moment from "moment";
import { getFacturePrice, getFactureTagClass, getFactureTagTitle } from "../../../../utils/utils";
import { useAppContext } from "../../../../context/app.context";

export default function ListFacturesPage() {
  const { appInfos } = useAppContext();
  ["À régler", "En retard", "Réglées", "Refusées"];
  const factureTypes = [
    {
      label: "À régler",
      value: "À régler",
    },
    {
      label: "En retard",
      value: "En retard",
    },
    {
      label: "Réglées",
      value: "Réglée",
    },
    {
      label: "Refusées",
      value: "Refusée",
    },
  ];
  const columns = [
    { accessorKey: "title", header: "Facture" },
    {
      accessorKey: "numFacture",
      enableSorting: false,
      header: "Numéro",
      accessorFn: (originalRow: any) => "HYK" + originalRow.numFacture?.padStart(4, "0"),
    },
    {
      accessorKey: "emissionDate",
      header: "Date d’émission",
      accessorFn: (originalRow: any) => moment(originalRow.emissionDate).format("DD/MM/YYYY"),
    },

    {
      accessorFn: (originalRow: any) => originalRow.recepteur.first_name + " " + originalRow.recepteur.last_name,
      header: "Client",
      enableSorting: true,
    },
    {
      accessorKey: "priceTTC",
      enableSorting: false,
      header: "Montant",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      accessorFn: (originalRow: any) => getFacturePrice(originalRow, appInfos.user),
      Cell: ({ cell, row }: any) => {
        return <span className={row.original.status == "Refusée" ? "tline" : ""}>{cell.getValue()}</span>;
      },
    },
    {
      accessorKey: "status",
      enableSorting: false,
      filterVariant: "multi-select",
      filterSelectOptions: factureTypes,
      header: "Statut",
      accessorFn: (originalRow: any) => getFactureTagTitle(originalRow),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell, row }: any) => {
        return (
          <span style={{ height: 40 }} className={getFactureTagClass(row.original)}>
            {cell.getValue()}
          </span>
        );
      },
    },
  ];
  const navigate = useNavigate();
  return (
    <Structurelayout contentStyle={{ paddingTop: 12, paddingBottom: 12 }} header={GestionHeader()}>
      <Table
        buttonTitle="Ajouter une facture"
        showFilter={true}
        showSearch={true}
        emptytext="Aucune facture disponible"
        filtertitle={"statut"}
        filtername={"status"}
        renderRowAction={(p: any) => (
          <div onClick={() => navigate("/structure/gestion/factures/" + p.row.original._id)}>
            <span style={{ marginLeft: 20 }} className="action">
              <img
                src={
                  p.row.original.status == "Refusée" || p.row.original.status == "Réglée"
                    ? "/images/facteye.svg"
                    : "/images/facregler.svg"
                }
                alt="download"
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
        )}
        filterOptions={factureTypes}
        dataUrl={"structure/factures"}
        searchplaceholder="Rechercher une facture..."
        title={"Gestion Factures"}
        columns={columns}
      />
    </Structurelayout>
  );
}
export function GestionHeader() {
  const router = useLocation();
  const navigate = useNavigate();
  function linkStatus(link: any) {
    return router.pathname.indexOf(link) == -1 ? "btn-link-inactive" : "";
  }
  return (
    <div>
      <h1 className="headertitle">Ma gestion</h1>
      <div
        className="row"
        style={{
         
          marginTop: 30,
          marginBottom: 10,
        }}
      >
        <div className="col-auto" style={{padding:0}}>
          <Button
            onClick={() => navigate("/structure/gestion/factures")}
            className={"btn btn-primary btn-link " + linkStatus("/factures")}
            title="Factures"
          />
        </div>
        <div className="col-auto" style={{ marginLeft: 20 ,padding:0 }}>
          <Button
            onClick={() => navigate("/structure/gestion/documents")}
            className={"btn btn-primary btn-link " + linkStatus("/documents")}
            title="Documents"
          />
        </div>
      </div>
    </div>
  );
}
