// ClientHeader.tsx
import React, { useEffect, useState } from "react";
import "./ClientHeader.css";
import { chooseFromFileSystem, readLocalFile } from "../../utils/files.utils";

interface ClientHeaderProps {
  profilePicture: string;
  name: string;
  userRole: string;
  onUpdate: () => void;
  onDelete: () => void;
}

const ClientHeader = ({ profilePicture, name, userRole, onUpdate, onDelete, hideButtons, onEditImage }: any) => {
  const [error, seterror] = useState(false);
  useEffect(() => {
    seterror(false);
  }, [profilePicture]);
  const [file, setfile]: any = useState(null);
  async function onPhotoChange() {
    if(!onEditImage)
      return 
    const files: any = await chooseFromFileSystem({ patternFile: [".jpeg", ".png", ".webp"], multiple: false });
    const file = await readLocalFile(files[0]);
    setfile(file);
    if (onEditImage) {
      onEditImage(files[0]);
    }
  }

  return (
    <div>
      <div className="client-header" style={{ paddingBottom: 10, position: "relative" }}>
      <div className="row gy-3 align-items-center" style={{width:'100%'}}>
        <div className="col-auto">
        <div onClick={onPhotoChange}>
          {onEditImage ? (
            <img
              style={{ position: "absolute", width: 40, height: 40, top: 20, left: 116 }}
              alt=""
              src="/images/edit.svg"
            />
          ) : null}
          <img
            onError={() => seterror(true)}
            style={{ width: 161, height: 161 }}
            src={file || (error ? "/images/default.svg" : profilePicture)}
            alt="Client Profile"
            className="profile-picture"
          />
        </div>
        </div>
        <div className="col-auto">
        <div className="client-info">
          <h1>{name}</h1>
          <span className="subtitle">{userRole}</span>
        </div>
        </div>
        <div className="col"></div>
        <div style={{padding:0}} className="col-auto">
        {!hideButtons && (
          <div className="actions">
            <div className="row gy-3">
              <div style={{padding:0}} className="col-auto">            <button onClick={onUpdate}>Modifier</button>              </div>
              <div style={{padding:0}}  className="col-auto">      <button onClick={onDelete}>Supprimer</button></div>
            </div>
      
          </div>
        )}
        </div>
      </div>
      
      
       
      </div>
    </div>
  );
};

export default ClientHeader;
