import React from "react";
import "./logolayout.css";

import Progress from "../progress/progress";
import { Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../button/button";
export default function LogoLayout(props: {
  progress?: number;
  hideBack?: boolean;
  params?: any;
  children?: any;
  onSubmit?: any;
  submitTitle?: string;
  showLogo?: boolean;
  showMobileBack?: boolean;
  renderBeforeSubmit?: any;
  renderAfterSubmit?: any;
  onMobileBack?: any;
}) {
  const canGoBack = window.history.state.idx !== 0;
  const methods = useForm({ mode: "onBlur" });
  return (
    <div className="row logolayout">
      <div className="col-md-6 leftbar">
        <div className="topelement">
          <img className="im1" src={"/images/ele1.png"} alt="" />
        </div>
        <div className="logo">
          <img src={"/images/Logo.svg"} alt="" />
          <span
            style={{ fontFamily: "Rubik-bold", fontSize: 30, display: "block", marginTop: 10 }}
            className="title logotitle"
          >
            HYKONNECT
          </span>
        </div>
        <div className="bottomelement">
          <img className="im2" src={"/images/ele2.png"} alt="" />
        </div>
      </div>
      <div className="col formsection d-flex align-items-center justify-content-center ">
        <div className="col">
          {!props?.params?.fromMobileApp&&<div className="topelementsmall">
            <img className="im1" src={"/images/ele1.png"} alt="" />
          </div>}
          {!props?.params?.fromMobileApp&&<div className="bottomelementsmall">
            <img className="im2" src={"/images/ele2.png"} alt="" />
          </div>}
          <div className="section2">
            <div>
              {canGoBack && !props.hideBack && (
                <div>
                  <Link to={-1 as any}>
                    <div className="backcontainer">
                      <img className="backimg" src={"/images/left-arrow.svg"} alt="" />
                      <h2 className="backtext">Retour</h2>
                    </div>
                  </Link>
                </div>
              )}
              {props.showMobileBack && (
                <div onClick={props.onMobileBack}>
                  <div className="backcontainer">
                    <img className="backimg" src={"/images/left-arrow.svg"} alt="" />
                    <h2 className="backtext">Retour</h2>
                  </div>
                </div>
              )}
              {props.progress && <Progress progress={props.progress} />}
            </div>
            {props.showLogo && (
              <div className="logosmall">
                <img src={"/images/Logo.svg"} alt="" />
              </div>
            )}
            <FormProvider {...methods}>
              {props.children}
              {props.onSubmit ? (
                <div style={{marginBottom:props?.params?.fromMobileApp? 80:0 }}  className="bottom-container">
                  {props.renderBeforeSubmit ? props.renderBeforeSubmit() : null}
                  <Button
                    onClick={methods.handleSubmit(props?.onSubmit)}
                    className="btn-primary"
                    title={props.submitTitle || "Suivant"}
                  />
                  {props.renderAfterSubmit ? (
                   props.renderAfterSubmit()
                  ) : null}
                </div>
              ) : null}
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
