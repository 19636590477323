import React from "react";
import StructureLayout from "../../../components/structureLayout/structurelayout";
import Table from "../../../components/table/table";
import moment from "moment";
import { formatPhone } from "../../../utils/utils";
export default function ClientsPage() {
  const columns = [
    { accessorKey: "first_name", header: "Prénom" },
    { accessorKey: "last_name", header: "Nom" },
    {
      accessorFn: (originalRow: any) => formatPhone(originalRow.phone).replaceAll(".", " "),
      header: "Téléphone",
      enableSorting: false,
    },
    { accessorKey: "role", header: "Client", enableSorting: false },
    {
      accessorKey: "createdAt",
      accessorFn: (originalRow: any) => moment(originalRow.createdAt).format("DD/MM/YYYY"),
      header: "Date d'inscription",
      enableSorting: true,
    },
    {
      accessorKey: "isAppUser",
      accessorFn: (originalRow: any) => (originalRow.isAppUser == true ? "Oui" : "Non"),
      enableSorting: false,
      header: "Hykonnect",
    },
  ];

  return (
    <StructureLayout contentStyle={{ paddingTop: 12, paddingBottom: 12 }} header={<Header />}>
      <Table
        columns={columns}
        dataUrl="structure/clients"
        showSearch={true}
        searchplaceholder="Rechercher un client..."
        buttonTitle={"Ajouter un client"}
        showFilter={false}
        emptytext="Aucun client disponible"
        RowClickable={false}
        deleteModalDesc={
          <>
            <span>Vous êtes sur le point de supprimer la fiche d’un client, et l’ensemble de ses informations.</span>
            <div style={{ marginTop: 7, marginBottom: 6 }}>
              Êtes-vous sûr de vouloir supprimer la fiche ? Cette action est irréversible.
            </div>
          </>
        }
        deleteModalTitle={"Supprimer un client"}
      />
    </StructureLayout>
  );
}

function Header() {
  return (
    <div style={{ marginBottom: 15 }}>
      <h1 className="headertitle">Mes clients</h1>
    </div>
  );
}
