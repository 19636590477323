import React, { useEffect, useState } from "react";
import Structurelayout from "../../../components/structureLayout/structurelayout";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { useAppContext } from "../../../context/app.context";
import Button from "../../../components/button/button";
import { FormProvider, useForm } from "react-hook-form";
import { formatPhone, formatSiret, phonereg, showToast, sirenreg } from "../../../utils/utils";
import PlacesInput from "../../../components/form/inputs/places-input/places-inputs";
import SelectInput from "../../../components/form/inputs/select-input/select-input";
import axiosInstance from "../../../config/axios";
import * as _ from "lodash";
export default function StructurePage() {
  const { appInfos, setAppInfos } = useAppContext();
  const methods = useForm({ mode: "onBlur" });
  const [structure, setstructure] = useState({ ...appInfos.user.structure });
  const disabled = _.isEqual(structure, appInfos.user.structure);
  const types = [
    { label: "Ecurie", value: "Ecurie" },
    { label: "Centre équestre", value: "Centre équestre" },
    { label: "Ferme équestre", value: "Ferme équestre" },
    { label: "Haras", value: "Haras" },
    { label: "Elevage", value: "Elevage" },
  ];
  async function onSubmit(form: any) {
    try {
      const { data } = await axiosInstance.post("/users/account/update", { structure: form });
      setAppInfos({ user: { ...appInfos.user, ...data } });
      showToast("Votre compte a été bien mis a jour", "success");
    } catch (e: any) {
      showToast("Une erreur est survenue");
    }
  }
  return (
    <Structurelayout header={Header()}>
      <FormProvider {...methods}>
        <div className="graycard">
          <div className="row">
            <h3 style={{ marginBottom: 10 }}>Votre structure</h3>
            <div className="col-md-4">
              <TextInput
                required="Nom de la structure invalide"
                placeHolder="Nom de la structure"
                label="Nom de la structure"
                value={structure.name}
                onChange={(value: any) => setstructure((old: any) => ({ ...old, name: value }))}
                name="name"
              />
            </div>
            <div className="col-md-4">
              <TextInput
                validate={(val: any) => (!sirenreg.test(val?.replaceAll(" ", "")) ? "SIRET invalide" : true)}
                maxLength={17}
                format={formatSiret}
                placeHolder="000 000 000 00000"
                label="SIRET"
                value={structure.siret}
                onChange={(value: any) => setstructure((old: any) => ({ ...old, siret: value }))}
                name="siret"
              />
            </div>
            <div className="col-md-4">
              <SelectInput
                placeHolder="Ecurie, Elevage..."
                onChange={(value: any) => setstructure((old: any) => ({ ...old, type: value.value }))}
                value={structure.type}
                options={types}
                name="type"
                label="Type de structure"
                required="Il faux sélectionner le  type de la structure"
              />
            </div>
            <div className="col-md-4">
              <PlacesInput
                value={structure.address}
                required
                placeHolder="Adresse postale"
                label="Adresse postale"
                name="address"
                onChange={(value: any) => setstructure((old: any) => ({ ...old, address: value }))}
              />
            </div>
            <div className="col-md-4">
              <TextInput
                validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
                name="phone"
                value={structure.phone}
                format={formatPhone}
                onChange={(value: any) => setstructure((old: any) => ({ ...old, phone: value }))}
                maxLength={14}
                placeHolder="06.00.00.00.00"
                label="Téléphone de la structure"
              />
            </div>
          </div>
        </div>
        <div className="mt-4 flexEnd">
          <div>
            <Button
              className="btn-primary graydisabled"
              onClick={methods.handleSubmit(onSubmit)}
              title="Enregistrer"
              disabled={disabled}
            />
          </div>
        </div>
      </FormProvider>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Mon profil</h1>
    </div>
  );
}
