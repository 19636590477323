import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../../../config/axios";
import Structurelayout from "../../../../components/structureLayout/structurelayout";
import Button from "../../../../components/button/button";
import { formatPrice, getFactureTagClass, getFactureTagTitle, showToast } from "../../../../utils/utils";
import Page404 from "../../../404/404.page";
import moment from "moment";
import { Modal as Mmodal } from "react-responsive-modal";
import CheckBoxInput from "../../../../components/form/inputs/check-input/check-input";
import { useAppContext } from "../../../../context/app.context";
import Modal from "../../../../components/modal/modal";

// Define the structure for a single Facture

const FacturePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [facture, setFacture]: any = useState({});
  const [loading, setLoading] = useState(false);
  const [is404, setis404] = useState(false);
  const fetchFacture = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/factures/${id}`);
      setFacture(response.data.data);
    } catch (error: any) {
      if (error?.response?.status == 422 || error?.response?.status == 404) {
        setis404(true);
      } else {
        showToast("Une erreur est survenue");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (id) fetchFacture();
  }, [id]);

  // Calculate VAT and totals based on prestations
  const totalHT = facture?.prestations?.reduce((acc: any, prestation: any) => acc + parseFloat(prestation.ht), 0);
  const totalTTC = facture?.prestations?.reduce((acc: any, prestation: any) => acc + prestation.ttc, 0);
  const totalTVA = totalTTC - totalHT;
  const navigate = useNavigate();
  const [showmodal, setshowmodal] = useState(false);
  const [showrefusemodal, setshowrefusemodal] = useState(false);
  const [showdeletemodal, setshowdeletemodal] = useState(false);
  const isDépense = facture?.type == "Dépense";
  const [paymethod, setpaymethod]: any = useState(null);
  async function onPay() {
    try {
      if (paymethod == "Via l’application Hyko") {
        const { data } = await axiosInstance.post("factures/pay/" + facture._id, {
          fromweb: true,
          successUrl: window.location.origin + "/structure/gestion/factures/" + facture._id,
          cancelUrl: window.location.origin + "/structure/gestion/factures/" + facture._id,
        });
        setshowmodal(false);
        window.location.href = data.session.url;
      } else {
        await axiosInstance.post("factures/validate/" + facture._id, {
          paymentMethod: paymethod,
        });
        await fetchFacture();
        setshowmodal(false);
      }
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }

  async function onConfirmRefuse() {
    try {
      await axiosInstance.post("/factures/reject/" + facture._id);
      await fetchFacture();
      setshowrefusemodal(false)
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  function onRefuse() {
    setshowrefusemodal(true);
  }
  function onDelete() {
    setshowdeletemodal(true);
  }
  const onConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`/factures/${facture._id}`);
      setshowdeletemodal(false)
      showToast("Facture supprimée avec succès", "success");
      navigate("/structure/gestion/factures"); // Navigate to facture list page

    } catch (error) {
      showToast("Échec de la suppression de la facture");
    }
  };
  const { appInfos } = useAppContext();
  if (is404) return <Page404 />;
  return (
    <Structurelayout
      loading={loading || !facture}
      showBack={true}
      header={
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <h2>Ma facture</h2>
          {facture._id && (
            <div className="actions">
              <a
                target="_blank"
                rel="noreferrer"
                href={process.env.REACT_APP_SERVER_URL + "/api/factures/pdf/" + facture._id}
              >
                <Button title="Télécharger" className="btn-download" />
              </a>
            </div>
          )}
        </div>
      }
    >
      <div className="row">
        <div className="col">
          <div className="graycard">
            <div className="row">
              <div className="col">
                <h3>{facture.title}</h3>
              </div>
              <div className="col-auto">
                <span style={{ height: 40 }} className={getFactureTagClass(facture)}>
                  {getFactureTagTitle(facture)}
                </span>
              </div>
            </div>
            <div>
              <span className="currentXS">{"HYK" + facture.numFacture?.padStart(4, "0")}</span>
            </div>
            <div
              style={{ width: "100%", marginTop: 15 }}
              className={"bigtagfacture " + getFactureTagClass(facture, true)}
            >
              <div className="row">
                <div className="col">
                  <span className="currentR">
                    <img
                      alt=""
                      style={{ marginRight: 10, marginTop: -6 }}
                      src={
                        "/images/" +
                        (facture.status == "Réglée"
                          ? "tagsuccess.svg"
                          : facture.status == "Refusée"
                            ? "tagerror.svg"
                            : "tagwarning.svg")
                      }
                    />
                    Facture
                    {facture.status == "Réglée" ? " payée" : facture.status == "Refusée" ? " refusée" : " à régler"}
                  </span>
                </div>
                {facture.status != "Refusée" && (
                  <div className="col-auto">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                      <div>
                        <span className="currentR">
                          {facture.status == "Réglée"
                            ? "le " + moment(facture.paymentDate).format("DD/MM/YYYY")
                            : "avant " + moment(facture.echeanceDate).format("DD/MM/YYYY")}
                        </span>
                      </div>
                      {facture.status == "Réglée" && (
                        <div style={{ marginTop: 5, float: "right" }}>
                          <span className="currentR">{facture.paymentMethod}</span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div style={{ marginTop: 15 }} className="row">
              <div className="col-6">
                <span style={{ color: "#364349", fontSize: 16 }} className="currentR">
                  Émetteur
                </span>
                <div style={{ marginTop: 3 }}>
                  <span className="currentM">
                    {!isDépense
                      ? (facture?.emetteur?.first_name || "") + " " + (facture?.emetteur?.last_name || "")
                      : (facture?.recepteur?.first_name || "") + " " + (facture?.recepteur?.last_name || "")}
                  </span>
                </div>
              </div>
              <div className="col-6">
                <span style={{ color: "#364349", fontSize: 16 }} className="currentR">
                  Destinataire
                </span>
                <div style={{ marginTop: 3 }}>
                  <span className="currentM">
                    {isDépense
                      ? (facture?.emetteur?.first_name || "") + " " + (facture?.emetteur?.last_name || "")
                      : (facture?.recepteur?.first_name || "") + " " + (facture?.recepteur?.last_name || "")}
                  </span>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: 7 }}>
              <div className="col-6">
                <span style={{ color: "#364349", fontSize: 16 }} className="currentR">
                  {"Date d'écheance"}
                </span>
                <div style={{ marginTop: 3 }}>
                  <span className="currentM">{moment(facture.echeanceDate).format("DD/MM/YYYYY")}</span>
                </div>
              </div>
              <div className="col-6">
                <span style={{ color: "#364349", fontSize: 16 }} className="currentR">
                  Date d’émission
                </span>
                <div style={{ marginTop: 3 }}>
                  <span className="currentM">{moment(facture.emissionDate).format("DD/MM/YYYYY")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="graycard">
            <h3>Détails</h3>
            <div
              style={{
                padding: 16,
                marginTop: 16,
                paddingTop: 0,
                borderRadius: 16,
                borderStyle: "dotted",
                borderWidth: 1,
                borderColor: "#E6E7E8",
              }}
            >
              {facture?.prestations?.map((elt: any, ind: any) => {
                return (
                  <>
                    <div style={{ marginTop: 16 }} className="row" key={ind}>
                      <div style={{ padding: 0, margin: 0 }} className="row">
                        <div className="col">
                          <span style={{ fontSize: 16 }} className="currentR">
                            {elt.title + " HT"}
                          </span>
                        </div>
                        <div style={{ padding: 0, margin: 0 }} className="col-auto">
                          <span style={{ fontSize: 16 }} className="currentR">
                            {formatPrice(elt.ht) + " €"}
                          </span>
                        </div>
                      </div>
                      <div style={{ padding: 0, margin: 0, marginTop: 10 }} className="row">
                        <div className="col">
                          <span style={{ fontSize: 16 }} className="currentR">
                            {"TVA " + elt.tva + "%"}
                          </span>
                        </div>
                        <div style={{ padding: 0, margin: 0 }} className="col-auto">
                          <span style={{ fontSize: 16 }} className="currentR">
                            {formatPrice(+elt.ttc - +elt.ht) + " €"}
                          </span>
                        </div>
                      </div>
                      <div style={{ padding: 0, margin: 0, marginTop: 10 }} className="row">
                        <div className="col">
                          <span style={{ fontSize: 16 }} className="currentR">
                            {elt.title + " TTC"}
                          </span>
                        </div>
                        <div style={{ padding: 0, margin: 0 }} className="col-auto">
                          <span style={{ fontSize: 16 }} className="currentR">
                            {formatPrice(elt.ttc) + " €"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "100%", height: 1, backgroundColor: "#E6E7E8", marginTop: 16 }}></div>
                  </>
                );
              })}
              <>
                <div style={{ marginTop: 16 }} className="row">
                  <div style={{ padding: 0, margin: 0 }} className="row">
                    <div className="col">
                      <span style={{ fontSize: 16 }} className="currentR">
                        {"Total HT"}
                      </span>
                    </div>
                    <div style={{ padding: 0, margin: 0 }} className="col-auto">
                      <span style={{ fontSize: 16 }} className="currentR">
                        {formatPrice(facture.priceHT) + " €"}
                      </span>
                    </div>
                  </div>
                  <div style={{ padding: 0, margin: 0, marginTop: 2 }} className="row">
                    <div className="col">
                      <span style={{ fontSize: 16 }} className="currentR">
                        {"Total TVA"}
                      </span>
                    </div>
                    <div style={{ padding: 0, margin: 0 }} className="col-auto">
                      <span style={{ fontSize: 16 }} className="currentR">
                        {formatPrice(facture.tva) + " €"}
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%", height: 1, backgroundColor: "#E6E7E8", marginTop: 16 }}></div>
              </>
              <>
                <div style={{ marginTop: 16 }} className="row">
                  <div className="row" style={{ padding: 0, margin: 0 }}>
                    <div className="col">
                      <span className="currentM">{"Total TTC"}</span>
                    </div>
                    <div style={{ padding: 0, margin: 0 }} className="col-auto">
                      <div style={{ display: "flex", justifyContent: "flex-end", float: "right" }}>
                        <span className="currentM">{formatPrice(facture.priceTTC) + " €"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {facture.status === "Refusée" && (
        <div className="mt-4" style={{ width: "20%", display: "flex", justifyContent: "flex-end", marginLeft: "auto" }}>
          <Button title="Supprimer la facture" className="btn-primary" onClick={onDelete} />
        </div>
      )}

      {/* Only show if not "Réglée" or "Refusée" */}
      {facture.status !== "Réglée" && facture.status !== "Refusée" && (
        <div className="mt-4 flexEnd" style={{ flexGrow: 1, alignItems: "flex-end", marginLeft: "auto" }}>
          {isDépense && (
            <div>
              <Button onClick={onRefuse} className="btn-secondary" title="Refuser la facture" />
            </div>
          )}
          {isDépense ? (
            <div style={{ marginLeft: 15 }}>
              <Button onClick={() => setshowmodal(true)} className="btn-primary" title="Payer la facture" />
            </div>
          ) : (
            <div style={{ marginLeft: 15 }}>
              <Button onClick={() => setshowmodal(true)} className="btn-primary" title="Marquer comme réglée" />
            </div>
          )}
        </div>
      )}
      <Modal
        show={showdeletemodal}
        title={"Supprimer une facture"}
        onConfirm={onConfirmDelete}
        description={
          <>
            <span>Vous êtes sur le point de supprimer cette facture.</span>
            <div style={{ marginTop: 7, marginBottom: 6 }}>
              Êtes-vous sûr de vouloir supprimer la facture ? Cette action est irréversible.
            </div>
          </>
        }
        onClose={() => setshowdeletemodal(false)}
      />
      <Modal
        show={showrefusemodal}
        title={"Réfuser une facture"}
        onConfirm={onConfirmRefuse}
        description={
          <>
            <span>Vous êtes sur le point de réfuser cette facture.</span>
            <div style={{ marginTop: 7, marginBottom: 6 }}>
              Êtes-vous sûr de vouloir réfuser la facture ? Cette action est irréversible.
            </div>
          </>
        }
        onClose={() => setshowrefusemodal(false)}
      />
      <Mmodal
        closeIcon={() => null}
        classNames={{
          modal: "appmodal confirmfacture",
        }}
        open={showmodal}
        onClose={() => {
          setshowmodal(false);
        }}
        center
      >
        <div className="row">
          <div className="col">
            <span className="subtitle">Méthode de paiement</span>
          </div>
          <div className="col-auto">
            <button className="btnclose" onClick={() => setshowmodal(false)}>
              <img width={28} src="/images/close.svg" alt="" />
            </button>
          </div>
        </div>
        <div className="add-client-form" style={{ marginTop: 20 }}>
          <div className="graycard" style={{ backgroundColor: "#F4F4F7", padding: 16, borderRadius: 8 }}>
            {isDépense && facture.idRecepteur?.iban && (
              <div>
                <CheckBoxInput
                  value={paymethod == "Via l’application Hyko"}
                  onChange={(val: any) => {
                    if (val) setpaymethod("Via l’application Hyko");
                    else setpaymethod(null);
                  }}
                  label="Via l’application Hyko"
                />
              </div>
            )}
            <div style={{ marginTop: 20 }}>
              <CheckBoxInput
                value={paymethod == "Virement bancaire"}
                onChange={(val: any) => {
                  if (val) setpaymethod("Virement bancaire");
                  else setpaymethod(null);
                }}
                label="Virement bancaire"
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <CheckBoxInput
                value={paymethod == "Chèques/Espèces"}
                onChange={(val: any) => {
                  if (val) setpaymethod("Chèques/Espèces");
                  else setpaymethod(null);
                }}
                label="Chèques/Espèces"
              />
            </div>
          </div>
          <Button className="btn-primary mb-3 mt-3" disabled={paymethod == null} onClick={onPay} title={"Confirmer"} />
        </div>
      </Mmodal>
    </Structurelayout>
  );
};

export default FacturePage;
