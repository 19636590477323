import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Structurelayout from "../../../components/structureLayout/structurelayout";
import ClientHeader from "../../../components/client/ClientHeader";
import ClientInfo from "../../../components/client/ClientInfo";
import Table from "../../../components/table/table";
import axiosInstance from "../../../config/axios";
import Modal from "../../../components/modal/modal";
import moment from "moment";
import { getFacturePrice, getFactureTagClass, getFactureTagTitle, showToast } from "../../../utils/utils";
import { useAppContext } from "../../../context/app.context";
import Button from "../../../components/button/button";
import Page404 from "../../404/404.page";

interface Facture {
  _id: string;
  numFacture: string;
  title: string;
  priceTTC: number;
  priceHT: number;
  status: string;
  emissionDate: string;
}

interface Client {
  _id: string;
  phone: string;
  birth_date: string;
  email: string;
  first_name: string;
  last_name: string;
  galop?: string;
  address: {
    address: {
      address: string; // Full address
      street: string;
      streetNumber: string;
      zipCode: string;
      country: string;
      administrative_area_level_1: string;
      administrative_area_level_2: string;
      latitude: number;
      longitude: number;
    };
  };
  level?: string;
  role: string;
  tuteur?: {
    first_name: string;
    last_name: string;
    phone: string;
    address: string;
    birth_date: string;
    email: string;
  };
}

const SingleClient: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [facturesData, setFacturesData] = useState<Facture[]>([]);
  const [clientData, setClientData] = useState<Client | null>(null);
  const [tuteurData, setTuteurData] = useState<Client["tuteur"] | null>(null); // State for tutor data
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [opendelete, setOpendelete] = useState<boolean>(false); // State for controlling modal visibility
  const [selectedid, setSelectedid] = useState<string>(""); // Store the selected client ID
  const navigate = useNavigate(); // Initialize the navigate function for navigation
  const [is404, setis404] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch client data
        const clientResponse = await axiosInstance.get(`/structure/clients/${id}`);

        let tutorData = null;
        if (clientResponse.data.tuteur) {
          tutorData = clientResponse.data.tuteur;
        } else if (clientResponse.data.tuteurId) {
          const tuteurResponse = await axiosInstance.get(`/structure/tuteurs/${clientResponse.data.tuteurId}`);
          tutorData = tuteurResponse.data;
        }

        setFacturesData([]);
        setClientData(clientResponse.data || null);
        setTuteurData(tutorData || null);
      } catch (error: any) {
        if (error.response.status == 422 || error.response.status == 404) {
          setis404(true);
        } else {
          showToast("Une erreur est survenue");
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  // Delete handler
  const handleDelete = async (clientId: string) => {
    try {
      await axiosInstance.delete(`/structure/clients/${clientId}`);
      setOpendelete(false); // Close the modal after successful deletion
      navigate("/structure/clients"); // Redirect to the clients list page after successful deletion
    } catch (error: any) {
      setError("Failed to delete client. Please try again.");
    }
  };

  const openDeleteModal = (clientId: string) => {
    setSelectedid(clientId);
    setOpendelete(true); // Show the modal when delete is triggered
  };
  const { appInfos } = useAppContext();
  const columns = [
    { accessorKey: "title", header: "Facture" },
    {
      accessorKey: "numFacture",
      enableSorting: false,
      header: "Numéro",
      accessorFn: (originalRow: any) => "HYK" + originalRow.numFacture?.padStart(4, "0"),
    },
    {
      accessorKey: "emissionDate",
      header: "Date d’émission",
      accessorFn: (originalRow: any) => moment(originalRow.emissionDate).format("DD/MM/YYYY"),
    },

    {
      accessorFn: (originalRow: any) => originalRow.recepteur.first_name + " " + originalRow.recepteur.last_name,
      header: "Client",
      enableSorting: false,
    },
    {
      accessorKey: "priceTTC",
      enableSorting: false,
      header: "Montant",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      accessorFn: (originalRow: any) => getFacturePrice(originalRow, appInfos.user),
      Cell: ({ cell, row }: any) => {
        return <span className={row.original.status == "Refusée" ? "tline" : ""}>{cell.getValue()}</span>;
      },
    },
    {
      accessorKey: "status",
      enableSorting: false,
      filterVariant: "multi-select",
      header: "Statut",
      accessorFn: (originalRow: any) => getFactureTagTitle(originalRow),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell, row }: any) => {
        return (
          <span style={{ height: 40 }} className={getFactureTagClass(row.original)}>
            {cell.getValue()}
          </span>
        );
      },
    },
  ];
  if (is404) return <Page404 />;
  return (
    <Structurelayout
      showBack
      loading={loading}
      header={
        <ClientHeader
          profilePicture={process.env.REACT_APP_SERVER_URL + "/" + (clientData as any)?.photo || "/images/default.svg"}
          name={clientData ? `${clientData.first_name} ${clientData.last_name}` : ""}
          userRole={clientData ? clientData.role : ""}
          onUpdate={() => navigate("/structure/clients/" + clientData?._id + "/edit")} // Update handler can be implemented if needed
          onDelete={() => clientData && openDeleteModal(clientData._id)} // Show modal when delete is triggered
        />
      }
    >
      {/* Client Info (only for client) */}
      <ClientInfo
        telephone={clientData?.phone || ""}
        birthDate={moment(clientData?.birth_date).format("DD/MM/YYYY")}
        email={clientData?.email || ""}
        galop={clientData?.galop}
        address={clientData?.address?.address as any}
        level={clientData?.level}
        title="Informations"
      />

      {tuteurData && (
        <div>
          <ClientInfo
            istuteur
            first_name={tuteurData.first_name}
            last_name={tuteurData.last_name}
            telephone={tuteurData.phone || ""}
            birthDate={moment(tuteurData.birth_date).format("DD/MM/YYYY")}
            email={tuteurData.email || ""}
            address={(tuteurData?.address as any)?.address}
            title="Tuteur"
          />
        </div>
      )}

      <div style={{ overflow: "auto" }} className="graycard">
        <div style={{ marginBottom: 10 }} className="row">
          <div className="col">
            <h3 style={{ marginTop: -5 }}>Factures</h3>
          </div>
          <div className="col-auto">
            <Button
              onClick={() => navigate("/structure/gestion/factures/add", { state: clientData })}
              icon="/images/pluscirc.svg"
              title="Ajouter une facture"
              className="btn btn-tertiary"
            />
          </div>
        </div>
        <div style={{ overflow: "hidden" }}>
          <Table
            dataUrl={"structure/factures?client=" + id}
            columns={columns}
            disablePagination={true}
            emptytext="Aucune facture disponible"
            isLoading={loading}
            showExport={false}
            hideAdd={true}
            renderRowAction={(p: any) => (
              <div onClick={() => navigate("/structure/gestion/factures/" + p.row.original._id)}>
                <span style={{ marginLeft: 20 }} className="action">
                  <img
                    src={
                      p.row.original.status == "Refusée" || p.row.original.status == "Réglée"
                        ? "/images/facteye.svg"
                        : "/images/facregler.svg"
                    }
                    alt="download"
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </div>
            )}
          />
        </div>
      </div>
      {/* Modal for delete confirmation */}
      <Modal
        title="Supprimer un client"
        description="Êtes-vous sûr de vouloir supprimer ce client ?"
        onConfirm={async () => {
          await handleDelete(selectedid);
        }}
        onClose={() => {
          setOpendelete(false);
          setSelectedid("");
        }}
        show={opendelete}
      />
    </Structurelayout>
  );
};

export default SingleClient;
