import React from "react";
import LogoLayout from "../../../components/logolayout/logolayout";
import SelectInput from "../../../components/form/inputs/select-input/select-input";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { formatInputPrice, formatSiret, sirenreg } from "../../../utils/utils";

export default function RegisterPrestatairePage() {
  const location = useLocation();
  const params = location.state;
  const navigate = useNavigate();
  async function onSubmit(form: any) {
    form = { ...params, ...form };
    navigate("/register/prestataire/profil", { state: form });
  }
  const redirect = window.history.state.idx == 0;
  if (redirect) return <Navigate to={"/register"} replace />;
  return (
    <LogoLayout  params={params} onSubmit={onSubmit} progress={30}>
      <h1>Inscription</h1>
      <p className="subtitle">{params.role}</p>
      <h2 className="title">Mes informations</h2>
      <div>
        <TextInput
          validate={(val: any) => (!sirenreg.test(val.replaceAll(" ", "")) ? "SIRET invalide" : true)}
          maxLength={17}
          name="siret"
          format={formatSiret}
          placeHolder="000 000 000 00000"
          label="SIRET"
        />
        <TextInput
          validate={(val: any) => (val && isNaN(val) ? "Capital social invalide" : true)}
          name="capital"
          format={formatInputPrice}
          rightIcon={<span style={{ fontSize: 16, fontFamily: "Rubik", color: "#81898d" }}>€</span>}
          placeHolder="0 "
          label="Capital social"
        />
        <SelectInput
          placeHolder="Choix de la profession"
          required="Profession invalide"
          name="job"
          options={params?.lists?.Jobs.map((elt: any) => {
            return { label: elt, value: elt };
          })}
          label="Profession"
        />
      </div>
    </LogoLayout>
  );
}
