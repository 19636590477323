import React from "react";
import Structurelayout from "../../components/structureLayout/structurelayout";

const CGUPage = () => {
  return (
    <Structurelayout hideheader={true} showBack={true}>
      <div className="container bg-white py-8">
        <div className="col-md-8 mx-auto px-4">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold mb-4">CONDITIONS GENERALES DE VENTE – INTERNET</h1>
            <p className="text-gray-600">En vigueur au 18/11/2024</p>
          </div>

          <div className="space-y-8">
            {/* Article 1 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 1 - Champ d&apos;application</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Les présentes Conditions Générales de Vente (dites « CGV ») s&apos;appliquent, sans restriction ni
                  réserve à tout achat des services suivants :
                </p>
                <p className="text-start currentR">
                  Hykonnect est une plateforme de gestion des flux entre les professionnels du secteur équestre et les
                  cavaliers/propriétaires de chevaux tels que proposés par le Prestataire aux clients non professionnels
                  (« Les Clients ou le Client ») sur le site hykonnect.com.
                </p>
                <p className="text-start currentR">
                  Les caractéristiques principales des Services sont présentées sur le site internet hykonnect.com. Le
                  Client est tenu d&apos;en prendre connaissance avant toute passation de commande. Le choix et
                  l&apos;achat Service est de la seule responsabilité du Client.
                </p>
                <p className="text-start currentR">
                  Ces CGV sont accessibles à tout moment sur le site hykonnect.com et prévaudront sur toute autre
                  document.
                </p>
                <p className="text-start currentR">
                  Le Client déclare avoir pris connaissance des présentes CGV et les avoir acceptées en cochant la case
                  prévue à cet effet avant la mise en œuvre de la procédure de commande en ligne du site hykonnect.com.
                </p>
                <p className="text-start currentR">
                  Sauf preuve contraire, les données enregistrées dans le système informatique du Prestataire
                  constituent la preuve de l&apos;ensemble des transactions conclues avec le Client.
                </p>
                <div className="mt-6">
                  <p className="font-semibold">Les coordonnées du Prestataire sont les suivantes :</p>
                  <div className="mt-2">
                    <p className="text-start currentR">Hykonnect, SAS</p>
                    <p className="text-start currentR">Capital social de 15000 euros</p>
                    <p className="text-start currentR">Immatriculé au RCS de Le Mans, sous le numéro 925 159 261</p>
                    <p className="text-start currentR">138 rue du bourg belé</p>
                    <p className="text-start currentR">Email : gladys@hykonnect.com</p>
                    <p className="text-start currentR">Téléphone : 0602358793</p>
                  </div>
                </div>
                <p className="text-start currentR">
                  Des droits de douane ou autres taxes locales ou droits d&apos;importation ou taxes d&apos;état sont
                  susceptibles d&apos;être exigibles. Ils seront à la charge et relèvent de la seule responsabilité du
                  Client.
                </p>
              </div>
            </section>

            {/* Article 2 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 2 - Prix</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Les Services sont fournis aux tarifs en vigueur figurant sur le site hykonnect.com, lors de
                  l&apos;enregistrement de la commande par le Prestataire.
                </p>
                <p className="text-start currentR">Les prix sont exprimés en Euros, HT et TTC.</p>
                <p className="text-start currentR">
                  Les tarifs tiennent compte d&apos;éventuelles réductions qui seraient consenties par le Prestataire
                  sur le site hykonnect.com.
                </p>
                <p className="text-start currentR">
                  Ces tarifs sont fermes et non révisables pendant leur période de validité mais le Prestataire se
                  réserve le droit, hors période de validité, d&apos;en modifier les prix à tout moment.
                </p>
                <p className="text-start currentR">
                  Les prix ne comprennent pas les frais de traitement, d&apos;expédition, de transport et de livraison,
                  qui sont facturés en supplément, dans les conditions indiquées sur le site et calculés préalablement à
                  la passation de la commande.
                </p>
                <p className="text-start currentR">Le paiement demandé au Client correspond au montant total de l&apos;achat, y compris ces frais.</p>
                <p className="text-start currentR">
                  Une facture est établie par le Prestataire et remise au Client lors de la fourniture des Services
                  commandés.
                </p>
              </div>
            </section>

            {/* Article 3 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 3 - Commandes</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Il appartient au Client de sélectionner sur le site hykonnect.com les Services qu&apos;il désire
                  commander, selon les modalités suivantes :
                </p>
                <p className="text-start currentR">
                  Le client choisit un service qu&apos;il confirme à l&apos;aide d&apos;un formulaire. Il peut modifier
                  ce service dans son espace personnel avant la confirmation. La résiliation de son abonnement est
                  possible depuis son espace personnel.
                </p>
                <p className="text-start currentR">
                  La vente ne sera considérée comme valide qu&apos;après paiement intégral du prix. Il appartient au
                  Client de vérifier l&apos;exactitude de la commande et de signaler immédiatement toute erreur.
                </p>
                <p className="text-start currentR">
                  Toute commande passée sur le site hykonnect.com constitue la formation d&apos;un contrat conclu à
                  distance entre le Client et le Prestataire.
                </p>
                <p className="text-start currentR">
                  Le Prestataire se réserve le droit d&apos;annuler ou de refuser toute commande d&apos;un Client avec
                  lequel il existerait un litige relatif au paiement d&apos;une commande antérieure.
                </p>
                <p className="text-start currentR">Le Client pourra suivre l&apos;évolution de sa commande sur le site.</p>
                <p className="text-start currentR">
                  La passation d&apos;une commande sur le site hykonnect.com implique la conclusion d&apos;un contrat
                  d&apos;une durée minimum de 1 an renouvelable pour une même durée par tacite reconduction.
                </p>
                <p className="text-start currentR">Aux termes de l&apos;article L 215-1 du Code de la consommation, reproduit ci-dessous :</p>
                <p className="text-start currentR">
                  &quot;Pour les contrats de prestations de services conclus pour une durée déterminée avec une clause
                  de reconduction tacite, le professionnel prestataire de services informe le consommateur par écrit,
                  par lettre nominative ou courrier électronique dédiés, au plus tôt trois mois et au plus tard un mois
                  avant le terme de la période autorisant le rejet de la reconduction, de la possibilité de ne pas
                  reconduire le contrat qu&apos;il a conclu avec une clause de reconduction tacite.&quot;
                </p>
              </div>
            </section>

            {/* Article 4 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 4 - Conditions de paiement</h2>
              <div className="space-y-4">
                <p className="text-start currentR">Le prix est payé par voie de paiement sécurisé, selon les modalités suivantes :</p>
                <ul className="list-disc pl-6">
                  <li>paiement par carte bancaire</li>
                  <li>
                    ou paiement par virement bancaire sur le compte bancaire du Vendeur (dont les coordonnées sont
                    communiquées au Client lors de la passation de la commande)
                  </li>
                </ul>
                <p className="text-start currentR">Le prix est payable comptant par le Client, en totalité au jour de la passation de la commande.</p>
                <p className="text-start currentR">
                  Les données de paiement sont échangées en mode crypté grâce au protocole défini par le prestataire de
                  paiement agréé intervenant pour les transactions bancaires réalisées sur le site hykonnect.com.
                </p>
              </div>
            </section>

            {/* Article 5 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 5 - Fourniture des Prestations</h2>
              <div className="space-y-4">
                <p className="text-start currentR">Les Services commandés par le Client seront fournis selon les modalités suivantes :</p>
                <p className="text-start currentR">
                  service de gestion (facturation et paiement) pour les professionnels du secteur équin à destination
                  des cavaliers et propriétaires de chevaux.
                </p>
                <p className="text-start currentR">
                  Lesdits Services seront fournis dans un délai maximum de une semaine à compter de la validation
                  définitive de la commande du Client, dans les conditions prévues aux présentes CGV à l&apos;adresse
                  indiquée par le Client lors de sa commande sur le site hykonnect.com.
                </p>
              </div>
            </section>

            {/* Article 6 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 6 - Droit de rétractation</h2>
              <div className="space-y-4">
                <p className="text-start currentR">Selon les modalités de l&apos;article L221-18 du Code de la Consommation :</p>
                <p className="text-start currentR">
                  &quot;Pour les contrats prévoyant la livraison régulière de biens pendant une période définie, le
                  délai à compter de la réception du premier bien.&quot;
                </p>
              </div>
            </section>

            {/* Article 7 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 7 - Responsabilité du Prestataire - Garanties</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Le Prestataire garantit, conformément aux dispositions légales et sans paiement complémentaire, le
                  Client, contre tout défaut de conformité ou vice caché, provenant d&apos;un défaut de conception ou de
                  réalisation des Services commandés dans les conditions et selon les modalités suivantes :
                </p>
              </div>
            </section>

            {/* Article 8 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 8 - Données personnelles</h2>
              <div className="space-y-6">
                <p className="text-start currentR">
                  Le Client est informé que la collecte de ses données à caractère personnel est nécessaire à la vente
                  des Services et leur réalisation et délivrance, confiées au Prestataire. Ces données à caractère
                  personnel sont récoltées uniquement pour l&apos;exécution du contrat de prestations de services.
                </p>

                <div>
                  <h3 className="text-xl font-medium mb-3">8.1 Collecte des données à caractère personnel</h3>
                  <p className="text-start currentR">
                    Les données à caractère personnel qui sont collectées sur le site hykonnect.com sont les suivantes :
                  </p>
                  <p className="mt-2">
                    Ouverture de compte
                    <br />
                    Lors de la création du compte Client / utilisateur :
                  </p>
                  <ul className="list-disc pl-6 mt-2 space-y-1">
                    <li>Nom</li>
                    <li>Prénom</li>
                    <li>Adresse mail</li>
                    <li>Téléphone</li>
                    <li>Adresse</li>
                    <li>Galop validé</li>
                    <li>Niveau estimé</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-medium mb-3">8.2 Destinataires des données à caractère personnel</h3>
                  <p className="text-start currentR">
                    Les données à caractère personnel sont utilisées par le Prestataire et ses co-contractants pour
                    l&apos;exécution du contrat et pour assurer l&apos;efficacité de la prestation de services, sa
                    réalisation et sa délivrance.
                  </p>
                </div>

                <div>
                  <h3 className="text-xl font-medium mb-3">8.3 Limitation du traitement</h3>
                  <p className="text-start currentR">
                    Sauf si le Client exprime son accord exprès, ses données à caractère personnelles ne sont pas
                    utilisées à des fins publicitaires ou marketing.
                  </p>
                </div>

                <div>
                  <h3 className="text-xl font-medium mb-3">8.4 Durée de conservation des données</h3>
                  <p className="text-start currentR">
                    Le Prestataire conservera les données ainsi recueillies pendant un délai de 5 ans, couvrant le temps
                    de la prescription de la responsabilité civile contractuelle applicable.
                  </p>
                </div>

                <div>
                  <h3 className="text-xl font-medium mb-3">8.5 Sécurité et confidentialité</h3>
                  <p className="text-start currentR">
                    Le Prestataire met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en
                    matière de sécurité du numérique pour protéger les données personnelles contre les altérations,
                    destructions et accès non autorisés. Toutefois il est à signaler qu&apos;Internet n&apos;est pas un
                    environnement complètement sécurisé et le Prestataire ne peut garantir la sécurité de la
                    transmission ou du stockage des informations sur Internet.
                  </p>
                </div>
              </div>
            </section>
            {/* Article 9 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 9 - Propriété intellectuelle</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Le contenu du site hykonnect.com est la propriété du Vendeur et de ses partenaires et est protégé par
                  les lois françaises et internationales relatives à la propriété intellectuelle. Toute reproduction
                  totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit
                  de contrefaçon.
                </p>
              </div>
            </section>

            {/* Article 10 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 10 - Droit applicable - Langue</h2>
              <div className="space-y-4">
                <p className="text-start currentR">Les présentes CGV et les opérations qui en découlent sont régies et soumises au droit français.</p>
                <p className="text-start currentR">
                  Les présentes CGV sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou
                  plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.
                </p>
              </div>
            </section>

            {/* Article 11 */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">ARTICLE 11 - Litiges</h2>
              <div className="space-y-4">
                <p className="text-start currentR">
                  Pour toute réclamation merci de contacter le service clientèle à l&apos;adresse postale ou mail du
                  Prestataire indiquée à l&apos;ARTICLE 1 des présentes CGV.
                </p>
                <p className="text-start currentR">
                  Le Client est informé qu&apos;il peut en tout état de cause recourir à une médiation conventionnelle,
                  auprès des instances de médiation sectorielles existantes ou à tout mode alternatif de règlement des
                  différends (conciliation, par exemple) en cas de contestation.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Structurelayout>
  );
};

export default CGUPage;
